.best_price_plane {
  padding-top: 108px;
}
section.best_price_plane .smal_title::before {
  top: 5px;
  left: 23px;
}
.best_price_box {
  background: #f3f6ee;
  box-shadow: 0px 4px 35px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  max-width: 413px;
  width: 100%;
  padding: 30px;
  display: flex;
  flex-direction: column;
  height: 544px;
  margin: 15px;
}
.best_price_box_title h4 {
  color: #89a155;
}
.best_price_box_title {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px;
  height: 123px;
}
.best_price_box_title.text-center h4 + p {
  margin-top: 15px;
}
.best_price_box_title.text-center span {
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #27293e;
}
.best_price_box_list {
  padding-top: 61px;
}
.best_price_box_list ul.about_our_schol_ul li {
  padding-bottom: 21px;
  padding-left: 25px;
}
.best_price_box_btn button.btn.btn-primary {
  max-width: 100%;
  width: 100%;
}
.best_price_box_btn {
  margin-top: auto;
}
.best_price_plane .row {
  margin: 0 -15px !important;
}
.best_price_plane .faq_bottom_title {
  padding-top: 31px;
  padding-bottom: 101px;
}
.best_price_plane .faq_bottom_title h4 {
  font-weight: 700;
}
.best_price_box_title p {
  font-family: "Inter";
  font-weight: 700;
  font-size: 36px;
  line-height: 44px;
}
.best_price_box:hover .best_price_box_title {
  background: url("../../images/price_best_hover.png");
  max-width: 353px;
  width: 100%;
  height: 123px;
  background-size: cover;
  background-position: center;
}
.best_price_box:hover .best_price_box_title h4 {
  color: #fff;
}
.best_price_box:hover .best_price_box_title p {
  color: #fff;
}
.best_price_box:hover .best_price_box_title.text-center span {
  color: #ffffff;
}
.best_price_box:hover button.btn.btn-primary {
  background: #89a155;
}

@media (max-width: 1330px) {
  .best_price_plane .row {
    margin: 0 !important;
  }
  .best_price_box {
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 1230px) {
  .best_price_box_list {
    padding-top: 30px;
  }
  br {
    display: none;
  }
}

@media (max-width: 1140px) {
  .best_price_box_title p {
    font-size: 25px;
    line-height: 44px;
  }
  .best_price_box {
    max-width: 311px;
    margin: 15px;
  }
}
@media (max-width: 1030px) {
  .best_price_box {
    max-width: 298px;
  }
}
@media (max-width: 991px) {
  .best_price_plane {
    padding-top: 50px;
  }
  .best_price_box {
    max-width: 350px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .best_price_plane .faq_bottom_title {
    padding-bottom: 25px;
  }
  .best_price_plane .faq_bottom_title {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .best_price_box_list ul.about_our_schol_ul li {
    padding-left: 0;
  }
  section.best_price_plane .smal_title::before {
    left: 32px;
  }
  .best_price_box_title.text-center h4 + p {
    margin-top: 8px;
  }
  .best_price_box_title p {
    font-size: 22px;
    line-height: 44px;
  }
  .best_price_box_title {
    height: 103px;
  }
  .best_price_box_title p {
    font-size: 20px;
  }
}
