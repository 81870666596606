.error_wrapper.text-center {
  padding-top: 70px;
  padding-bottom: 175px;
}
.error_title {
  padding-top: 51px;
}
.error_btn {
  padding-top: 52px;
}
.error_wrapper.text-center button.btn.btn-primary {
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.error_wrapper.text-center {
  position: relative;
}
.error_bg_1 {
  position: absolute;
  top: 156px;
  left: 82px;
}
.error_bg_2 {
  position: absolute;
  top: 457px;
  left: 245px;
}
.error_bg_3 {
  position: absolute;
  bottom: 194px;
  left: 337px;
}
.error_bg_4 {
  position: absolute;
  bottom: 170px;
  right: 433px;
}
.error_bg_5 {
  position: absolute;
  bottom: 356px;
  right: 106px;
}
.error_bg_6 {
  position: absolute;
  top: 98px;
  right: 111px;
}
