.classes_workshop_wrapper {
  background: #f3f6ee;
  padding-top: 69px;
  padding-bottom: 100px;
}
.classes_workshop_wrapper a {
  text-decoration: none;
}
.classes_workshop_title_outer {
  max-width: 460px;
  width: 100%;
  padding-bottom: 85px;
}
.smal_title.smal_mid {
  max-width: 225px;
  width: 100%;
  margin: 0 auto;
}
.classes_workshop_title {
  padding-top: 21px;
}
.class_workshop_detail_outer {
  padding: 10px 0 48px 0;
  background: #27293e;
  border-radius: 0 0 8px 8px;
  transition: all 0.3s ease;
}
.class_workshop_detail_box_title h4 {
  color: #ffffff;
  font-weight: 700;
  max-width: 258px;
  width: 100%;
  margin: 0 auto;
}
.lass_workshop_detail_box_sub_1 p {
  color: #fff;
}
.class_workshop_detail_box_sub_detail {
  padding-top: 15px;
}
.lass_workshop_detail_box_sub_1::before {
  content: "";
  background: #ffffff;
  width: 10px;
  height: 10px;
  position: absolute;
  top: 3px;
  left: -14px;
  border-radius: 100%;
}
.lass_workshop_detail_box_sub_1 {
  position: relative;
  padding-right: 47px;
}
.lass_workshop_detail_box_sub_1:last-child {
  padding-right: 0;
}
.class_workshop_box {
  max-width: 413px;
  width: 100%;
  margin: 15px;
  transition: all 0.3s ease;
}
.classes_workshop_title span {
  color: #89a155;
  font-weight: 700;
  display: block;
}
.classes_workshop_wrapper .row {
  margin: 0 -15px !important;
}
.row {
  margin: 0 !important;
  padding: 0 !important;
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
  justify-content: space-between;
}
.class_workshop_box:hover .class_workshop_detail_outer {
  background: #89a155;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 0 0 5px 5px;
}
.class_workshop_detail_outer {
  position: relative;
}
.class_workshop_box:hover .class_workshop_detail_outer::after {
  content: "";
  background: url("../../images/classes_hover_img.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 40px;
  height: 40px;
  position: absolute;
  bottom: -14px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.classes_workshop_btn {
  padding-top: 50px;
}

@media (max-width: 1330px) {
  .classes_workshop_wrapper .row {
    margin: 0 0px !important;
  }
  .class_workshop_box {
    max-width: 370px;
    width: 100%;
    margin: 0 auto;
    transition: all 0.3s ease;
    margin-bottom: 15px;
  }
}

@media (max-width: 1230px) {
  .class_workshop_box {
    max-width: 320px;
  }
  .class_workshop_detail_outer {
    padding: 10px 7px 48px 19px;
  }
  .classes_workshop_wrapper.class_pg.Portfolio_pg
    .class_workshop_detail_box_title
    h4 {
    padding-top: 13px;
    font-size: 18px;
  }
}

@media (max-width: 1030px) {
  .class_workshop_box {
    max-width: 300px;
  }
  .lass_workshop_detail_box_sub_1 p {
    font-size: 14px;
  }
}
@media (max-width: 991px) {
  .why_choose_wrapper.whychoose_about_pg.class_pg {
    padding-top: 50px;
  }
  .class_workshop_detail_box_title h4{
    max-width: 100%;
  }
  .classes_workshop_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .class_workshop_box {
    max-width: 650px;
    width: 100%;
  }
  .class_workshop_img img {
    max-width: 100%;
    width: 100%;
  }
  .classes_workshop_wrapper .smal_title::before {
    left: 0;
    top: 6px;
  }
  .class_workshop_detail_outer {
    padding: 25px 7px 48px 19px;
  }
  .classes_workshop_btn {
    padding-top: 15px;
  }
  .classes_workshop_title {
    padding-top: 15px;
  }
  .classes_workshop_title_outer {
    max-width: 460px;
    padding-bottom: 45px;
  }
  .classes_workshop_title_outer {
    padding-bottom: 15px;
  }
  .classes_workshop_wrapper.class_pg.Portfolio_pg .class_workshop_detail_outer {
    padding: 25px 30px 20px 30px;
  }
}

@media (max-width: 575px) {
  .classes_workshop_wrapper .smal_title::before {
    left: 15px;
  }
  .why_choose_wrapper .smal_title::before {
    left: 14px;
  }
}
