@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500&family=Syne:wght@400;700&display=swap");

* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-size: 16px !important;
  line-height: 1.21 !important;
  font-weight: 400 !important;
  font-family: "Inter", sans-serif;
  background-color: #ffffff;
  color: #393939;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
  color: #27293e;
  font-family: "Syne", sans-serif;
}
h1,
.h1 {
  font-size: 60px !important;
  line-height: 1.2;
}
h2,
.h2 {
  font-size: 36px !important;
  line-height: 1.2 !important;
}
h3,
.h3 {
  font-size: 30px !important;
  line-height: 1.2 !important;
}
h4,
.h4 {
  font-size: 24px !important;
  line-height: 1.2 !important;
}
h5,
.h5 {
  font-size: 20px !important;
  line-height: 1.21 !important;
}
img {
  max-width: 100%;
  height: auto;
  border: none;
  vertical-align: middle;
}
p {
  margin: 0;
}
* + h1,
* + h2 {
  margin-top: 25px;
}
* + h3,
* + h4 {
  margin-top: 20px;
}
* + h5,
* + h6 {
  margin-top: 22px;
}
* + p {
  margin-top: 20px;
}
ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
  color: #27293e;
  transition: all ease 0.3s;
  -webkit-transition: all ease 0.3s;
}
a:hover {
  color: #89a155;
}

button.btn.btn-primary {
  padding: 14px 18px 14px 17px;
  background-color: #27293e;
  color: #ffffff;
  max-width: 100%;
  font-size: 16px;
  line-height: 1.21;
  display: inline-block;
  font-weight: 700;
  border: 1px solid transparent;
  font-family: "Syne", sans-serif;
  position: relative;
  transition: all 0.3s ease;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  border-radius: 5px;
}
button.btn.btn-primary:hover {
  background: transparent;
  box-shadow: none;
  border: 1px solid #27293e;
  color: #27293e;
}
button.btn.btn-primary span {
  padding-right: 10px;
}
.container {
  max-width: 1340px;
  width: 100%;
  margin: 0 auto;
  padding: 0 20px !important;
}

@media (max-width: 991px) {
  h1,
  .h1 {
    font-size: 41px !important;
  }
  h2,
  .h2 {
    font-size: 30px !important;
  }
  h4,
  .h4 {
    font-size: 20px !important;
  }
}

@media (max-width: 767px) {
  h5,
  .h5 {
    font-size: 17px !important;
  }
}

@media (max-width: 575px) {
  h2,
  .h2 {
    font-size: 21px !important;
  }
}

@media (max-width: 480px) {
  h2,
  .h2 {
    font-size: 25px !important;
  }
  button.btn.btn-primary {
    font-size: 12px !important;
  }
}

@media (max-width: 430px) {
  h1,
  .h1 {
    font-size: 35px !important;
  }
}
