.Pricing_wrapper {
  position: relative;
}
.pricing_left_side {
  height: 100%;
  position: relative;
}
.pricing_right_side {
  background: #27293e;
  padding: 110px 20px 100px 86px;
  height: 100%;
}
.Pricing_wrapper .container-fluid {
  padding: 0 !important;
}
.play_btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  border: none;
}
.pricing_right_side_content p {
  color: #ffffff;
  max-width: 474px;
  width: 100%;
  font-size: 16px;
  line-height: 19px;
}
.pricing_right_side_content {
  padding-top: 20px;
  position: relative;
}
.pricing_right_side_content::after {
  content: "";
  background: #89a155;
  position: absolute;
  top: 100px;
  left: 0;
  border: 1px solid #89a155;
  width: 50px;
  height: 1px;
}
.pricing_table_outer {
  padding-top: 30px;
  max-width: 518px;
  width: 100%;
  border-bottom: 1px dashed #89a155;
  margin-top: 38px;
}
.pricing_title h5 {
  color: #fff;
}
.pricing_price p {
  color: #fff;
}
.pricing_table_box {
  border-top: 1px dashed #89a155;
  padding: 20px 0;
}
.pricing_btn button.btn.btn-primary {
  background: #89a155;
  margin-top: 50px;
}
.quick_help_wrapper .classes_workshop_title {
  padding: 0;
}
.quick_help_wrapper {
  background: #f3f6ee;
  padding-top: 83px;
  padding-bottom: 536px;
}
.pricing_left_side img {
  height: 100%;
  width: 100%;
}

.quick_help_wrapper a {
  text-decoration: none;
}


@media (max-width: 1280px) {
  .pricing_right_side {
    padding: 110px 30px 100px 50px;
  }
}
@media (max-width: 991px) {
  .pricing_right_side {
    padding: 50px 30px;
  }
  .Pricing_wrapper .smal_title::before {
    left: 60px;
  }
  .pricing_right_side_content p {
    text-align: center;
    margin: 0 auto;
  }
  .pricing_right_side_content::after {
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: 0;
  }
  .pricing_btn button.btn.btn-primary {
    margin-top: 30px;
  }
  .pricing_table_outer {
    margin: 0 auto;
    margin-top: 25px;
  }
  .pricing_btn {
    text-align: center;
  }
  .quick_help_wrapper.blog_post_res_mob {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .pricing_table_box {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .pricing_right_side_content {
    padding-top: 9px;
  }
}
@media(max-width:364px){
  .pricing_right_side_content p {   font-size: 13px;}
 
}
@media (max-width: 360px) {
  .pricing_right_side_content p {
    font-size: 14px;
  }
}

@media(max-width:325px){
  .pricing_right_side_content::after { top: 100px;  }

}