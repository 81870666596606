.quick_help_detail_outer {
  padding-top: 27px;
}
.quick_help_title {
  padding-bottom: 51px;
}
.quick_help_call_right a {
  font-family: "Syne";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #27293e;
}
.quick_help_call_right address {
  margin: 0;
  font-family: "Syne";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #27293e;
}
.quick_help_call_right {
  width: calc(100% - 24px);
  padding-left: 21px;
}
.quick_help_call {
  width: 24px;
}
.form_wrapper form {
  background: #ffffff;
  box-shadow: 0px 4px 35px rgb(0 0 0 / 5%);
  border-radius: 5px;
  max-width: 1078px;
  width: 100%;
  padding: 50px;
  margin: 0 auto;
}
.news_blogs_wrapper {
  padding-top: 110px;
  padding-bottom: 100px;
  position: relative;
}
.news_blogs_wrapper a {
  text-decoration: none;
}
.news_blogs_bg_1 {
  position: absolute;
  bottom: 214px;
  right: 96px;
}
.news_blogs_bg_2 {
  position: absolute;
  top: 193px;
  left: 105px;
}
.news_blogs_wrapper .classes_workshop_title_outer {
  max-width: 578px;
  width: 100%;
  padding-bottom: 37px;
}
.news_blogs_wrapper .smal_title.smal_mid {
  max-width: 277px;
}
.news_blogs_box:hover .news_blogs_detail_box {
  background: #89a155;
}
.news_blogs_detail_box {
  background: #27293e;
  transition: all 0.3s ease;
  box-shadow: 0px 4px 35px rgb(0 0 0 / 5%);
  border-radius: 5px 5px 0 0;
  padding: 30px 30px 30px 30px;
}
.news_blogs_detail_box p {
  margin-top: 12px;
}
.news_blogs_detail_box h5 {
  color: #ffffff;
  margin-bottom: 0;
}
.news_blogs_detail_box p {
  color: #ffffff;
  margin-bottom: 0;
}
.news_blogs_img img {
  border-radius: 0px 0px 5px 5px;
}
.news_blogs_box.second:hover .news_blogs_detail_box {
  background-color: #89a155;
}
.news_blogs_box.second:hover .news_blogs_img_box {
  background-color: #89a155;
}
.news_blogs_box:hover .news_blogs_img_box {
  background-color: #89a155;
}
.news_blogs_img_box {
  max-width: 135px;
  width: 100%;
  background: #27293e;
  border-radius: 5px;
  text-align: center;
  padding: 11px 17px;
  position: absolute;
  top: 205px;
  right: 35px;
}
.news_blogs_img_box p {
  color: #fff;
  margin-bottom: 0;
}
.news_blogs_box {
  position: relative;
  margin: 0 15px;
}
.news_blogs_box {
  position: relative;
}
.news_blogs_box.second .news_blogs_detail_box {
  background: #27293e;
  box-shadow: 0px 4px 35px rgb(0 0 0 / 5%);
  border-radius: 0 0 5px 5px;
}
.news_blogs_box.second .news_blogs_img img {
  border-radius: 5px 5px 0px 0px;
}
.news_blogs_wrapper .row {
  margin: 0 -15px !important;
}
.news_blogs_box.second .news_blogs_img_box {
  top: 330px;
  right: 35px;
  background-color: #27293e;
}
.news_blogs_box.second {
  position: relative;
  transition: all 0.3s ease;
}
.news_blogs_btn {
  padding-top: 50px;
}
.news_blogs_btn button.btn.btn-primary {
  padding: 14px 40px 14px 40px;
}

/* blog page */
.news_blogs_wrapper.blog_pg .news_blogs_box {
  margin: 15px 15px;
}
.news_blogs_wrapper.blog_pg .news_blogs_img img {
  width: 100%;
  min-height: 400px;
}
.news_blogs_wrapper.blog_pg .news_blogs_detail_box {
  position: relative;
}
.news_blogs_box.blog_box:hover .news_blogs_detail_box::after {
  content: "";
  background: url("../../images/classes_hover_img.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 40px;
  height: 40px;
  left: auto;
  position: absolute;
  top: 166px;
  left: 30px;
}
.news_blogs_box.second.blog_box:hover .news_blogs_detail_box::after {
  top: -18px;
  left: 30px;
}

.class_List_wrapper.blog_list_pg .classes_workshop_title_outer {
  max-width: 578px;
  padding-bottom: 51px;
}
.class_List_wrapper.blog_list_pg .smal_title.smal_mid {
  max-width: 283px;
}
.clas_list_img img {
  width: 100%;
  height: 100%;
}
.class_list_date {
  position: absolute;
  top: 20px;
  left: 259px;
  background: #27293e;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  line-height: 19px;
  max-width: 134px;
  width: 100%;
  height: 40px;
  text-align: center;
  padding-top: 10px;
}
.class_List_wrapper .class_list_box {
  position: relative;
}
.class_List_wrapper.blog_list_pg .class_list_box:hover .class_list_date {
  background: #89a155;
}
.class_List_wrapper.blog_list_pg .class_list_box:hover .class_list_title h4 {
  color: #89a155;
}
.class_List_wrapper.blog_list_pg
  .class_list_box:hover
  .class_list_title::after {
  background-color: #89a155;
}
.class_List_wrapper.blog_list_pg
  .class_list_box:hover
  .class_list_detial
  .lass_workshop_detail_box_sub_1::before {
  background-color: #89a155;
}
.class_List_wrapper.blog_list_pg .class_list_box:hover button.btn.btn-primary {
  background: #89a155;
  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1),
    0px 4px 6px -2px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.class_List_wrapper.blog_list_pg .class_list_box {
  transition: all 0.3s ease;
}
.class_list_title::after {
  content: "";
  max-width: 50px;
  width: 100%;
  height: 5px;
  background: #27293e;
  position: absolute;
  top: 52px;
  border-radius: 100px;
}
.class_list_title {
  position: relative;
}
.class_list_btn {
  padding-top: 20px;
  padding-bottom: 25px;
}
.row {
  margin: 0 !important;
  padding: 0 !important;
  --bs-gutter-x: 0 !important;
  --bs-gutter-y: 0 !important;
  justify-content: space-between;
}

.news_blogs_box.odd {
  display: flex;
  flex-direction: column-reverse;
}
.news_blogs_box.odd .news_blogs_detail_box {
  border-radius: 0px 0px 5px 5px;
}
.news_blogs_box.odd .news_blogs_img img {
  border-radius: 5px 5px 0px 0px;
}
.news_blogs_box.odd .news_blogs_img_box {
  top: inherit;
  bottom: 224px;
}

@media (max-width: 1330px) {
  .news_blogs_wrapper .row {
    margin: 0 0px !important;
  }
  .news_blogs_box.second .news_blogs_img_box {
    top: 205px;
  }
}

@media (max-width: 1280px) {
  .class_list_title h4 {
    font-size: 20px;
  }
}
@media (max-width: 1140px) {
  .news_blogs_img_box {
    top: 230px;
    right: 35px;
  }
}

@media (max-width: 1060px) {
  .news_blogs_box.second .news_blogs_img_box {
    top: 230px;
  }
}

@media (max-width: 1030px) {
  .news_blogs_detail_box h5 {
    font-size: 19px;
  }
  section.news_blogs_wrapper.blog_pg .news_blogs_img_box {
    top: 243px;
    right: 35px;
  }
  section.news_blogs_wrapper.blog_pg .news_blogs_box {
    margin: 0 auto !important;
    margin-bottom: 20px !important;
  }
}

@media (max-width: 1000px) {
  .news_blogs_box.odd .news_blogs_img_box {
    bottom: 244px;
  }
  .news_blogs_img_box {
    top: 245px;
    right: 35px;
  }
}
@media (max-width: 991px) {
  .news_blogs_box.odd .news_blogs_img_box {
    bottom: inherit;
    top: 30px;
  }
  .news_blogs_bg_1 {
    display: none;
  }
  .news_blogs_bg_2 {
    display: none;
  }
  .news_blogs_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .news_blogs_wrapper .smal_title::before {
    left: 10px;
  }
  .news_blogs_wrapper .classes_workshop_title_outer {
    padding-bottom: 25px;
  }
  .news_blogs_img img {
    max-width: 100%;
    width: 100%;
  }
  .news_blogs_box {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 20px;
  }
  .news_blogs_detail_box {
    border-radius: 0px 0px 5px 5px;
    padding: 30px;
  }
  .news_blogs_img_box {
    right: 30px;
    top: 30px;
  }
  .news_blogs_box.second {
    margin-bottom: 20px;
  }
  .news_blogs_box.second {
    flex-direction: column;
  }
  .news_blogs_box.second .news_blogs_img_box {
    top: 30px;
    right: 30px;
  }
  .news_blogs_btn {
    padding-top: 20px;
  }
  section.news_blogs_wrapper.blog_pg .smal_title::before {
    left: 9px;
    top: 5px;
  }
  section.class_List_wrapper.blog_list_pg .class_list_date {
    position: inherit;
    top: 0;
    left: 0;
  }
  section.class_List_wrapper.blog_list_pg .clas_list_img {
    max-width: 413px;
    width: 100%;
  }
  section.class_List_wrapper.blog_list_pg .class_List_wrapper {
    padding-bottom: 0;
  }
  section.yoga_beginners_wrapper.blog_detail_pg .yoga_beginners_verify_img {
    width: 100px;
  }
  section.yoga_beginners_wrapper.blog_detail_pg .yoga_beginners_right_side_box {
    text-align: center;
  }
}

@media (max-width: 767px) {
  section.news_blogs_wrapper.blog_pg .smal_title::before {
    left: 24px;
    top: 5px;
  }
}

@media (max-width: 575px) {
  section.class_List_wrapper.blog_list_pg .class_list_title::after {
    top: 40px;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }
  section.class_List_wrapper.blog_list_pg .class_list_content {
    padding-top: 25px;
  }
}

@media (max-width: 480px) {
  .news_blogs_detail_box {
    padding: 15px;
  }
  section.class_List_wrapper.blog_list_pg .class_list_title::after {
    top: 55px !important;
  }
  section.class_List_wrapper.blog_list_pg .class_list_content {
    padding-right: 0;
    padding-left: 0;
  }
}

@media (max-width: 430px) {
  section.class_List_wrapper.blog_list_pg .class_list_detail_outer {
    padding: 20px;
  }
}
