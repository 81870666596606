.quick_help_wrapper .classes_workshop_title {
  padding: 0;
}
.quick_help_wrapper {
  background: #f3f6ee;
  padding-top: 83px;
  padding-bottom: 536px;
}
.pricing_left_side img {
  height: 100%;
  width: 100%;
}
.quick_help_detail_outer {
  padding-top: 27px;
}
.quick_help_title {
  padding-bottom: 51px;
}
.quick_help_call_right a {
  font-family: "Syne";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #27293e;
}
.quick_help_call_right address {
  margin: 0;
  font-family: "Syne";
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #27293e;
}
.quick_help_call_right {
  width: calc(100% - 24px);
  padding-left: 21px;
}
.quick_help_call {
  width: 24px;
}
.form_wrapper form {
  background: #ffffff;
  box-shadow: 0px 4px 35px rgb(0 0 0 / 5%);
  border-radius: 5px;
  max-width: 1078px;
  width: 100%;
  padding: 50px;
  margin: 0 auto;
}
.form_wrapper form h2 {
  padding-bottom: 58px;
}
.form_wrapper .form-row {
  padding-bottom: 28px;
}
.form_wrapper .form-group label {
  padding-bottom: 16px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #27293e;
}
.form_wrapper .form-control {
  max-width: 474px;
  width: 100%;
  padding: 17px 15px;
}
::placeholder {
  color: #27293e !important;
  font-family: "Inter";
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
}
.form_wrapper .form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #27293e;
  outline: 0;
  box-shadow: 0 0 0 -0.75rem rgb(39 41 62);
}
.form_wrapper .form_submit_btn {
  padding-top: 40px;
}
.form_wrapper textarea.form-control.msg {
  max-width: 100%;
}
.form_wrapper textarea.form-control {
  min-height: 159px;
}
.form_wrapper {
  margin-bottom: -960px;
  z-index: 1;
  position: relative;
  padding-top: 87px;
}

/* google map */
.contct_form_map iframe {
  max-width: 1105px;
  width: 100%;
}
.contct_form_map::after {
  content: "";
  border: 10px solid #fff;
  max-width: 1110px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0 auto;
}
.contct_form_map {
  position: relative;
  padding-top: 99px;
}

@media (max-width: 1140px) {
  .form_wrapper .form-control {
    max-width: 380px;
  }
}

@media (max-width: 1060px) {
  .contct_form_map iframe {
    max-width: 875px;
    width: 100%;
  }
  .contct_form_map::after {
    max-width: 890px;
  }
}
@media (max-width: 991px) {
  .quick_help_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .quick_help_title {
    padding-bottom: 20px;
  }
  .quick_help_detail_box h5 {
    text-align: center;
  }
  .quick_help_detail_outer.d-flex {
    max-width: 235px;
    width: 100%;
    margin: 0 auto;
  }
  .quick_help_detail_outer {
    padding-top: 15px;
  }
  .form_wrapper {
    margin-bottom: 0;
  }
  .quick_help_detail_box {
    padding-top: 15px;
  }
  .form_wrapper {
    padding-top: 50px;
  }
  .form-row {
    flex-direction: column;
  }
  .form_wrapper form {
    padding: 30px;
  }
  .form_wrapper .form-control {
    max-width: 100%;
  }
  .form_wrapper .form-group label {
    padding-top: 15px;
  }
  .form_wrapper form h2 {
    padding-bottom: 10px;
  }
  .quick_help_call_right a {
    font-size: 17px;
  }
  .quick_help_call_right address {
    font-size: 17px;
  }
  .form_wrapper.book_class_pg_form_wrapper {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .form_wrapper.book_class_pg_form_wrapper .form-control {
    max-width: 100%;
  }
  .form_wrapper.book_class_pg_form_wrapper form h2 {
    padding-bottom: 30px;
  }
  .form_wrapper.book_class_pg_form_wrapper form h2::after {
    bottom: 9px;
  }
  section.form_wrapper.book_class_pg_form_wrapper .form-row {
    padding-bottom: 0;
  }
  section.form_wrapper.book_class_pg_form_wrapper .form_submit_btn {
    padding-top: 25px;
  }
  section.form_wrapper.book_class_pg_form_wrapper .form-control {
    max-width: 474px;
    width: 100%;
    padding: 8px 15px;
  }
  .form_wrapper.book_class_pg_form_wrapper .form-control {
    max-width: 100% !important;
    width: 100%;
    padding: 8px 15px;
  }
}

@media (max-width: 480px) {
  .form_wrapper form {
    padding: 15px;
  }
  .form_wrapper .form-row {
    padding-bottom: 0;
  }
}
