.hero_outer {
  background: url("../../images/hero_img.png");
  max-width: 1720px;
  width: 100%;
  height: 852px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: block;
}
.troo_da_wrapper {
  position: relative;
  padding: 0px 0;
  z-index: 0;
  /* background: rgb(137,161,85);
background: linear-gradient(270deg, rgba(137,161,85,1) 43%, rgba(39,41,62,1) 43%); */
}
.troo_da_wrapper::before {
  content: "";
  background: #27293e;
  width: 50%;
  min-height: 770px;
  position: absolute;
  top: 0;
  left: 0;
}
.troo_da_wrapper::after {
  content: "";
  background: #89a155;
  width: 50%;
  height: 770px;
  position: absolute;
  top: 0;
  right: 0;
}
.troo_da_wrapper .container {
  max-width: 1760px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  position: relative;
  z-index: 2;
}
.hero_outer {
  position: relative;
}
.hero_detail {
  padding-top: 249px;
  padding-left: 210px;
  max-width: 955px;
  width: 100%;
}
.hero_title {
  padding-top: 18px;
}
.hero_content {
  padding-top: 18px;
  max-width: 734px;
  width: 100%;
}
.hero_btn {
  padding-top: 29px;
}
.hero_title span {
  color: #89a155;
  font-weight: 700;
}
.smal_title h5 {
  font-family: "Inter", sans-serif;
  font-weight: 400;
  z-index: 4;
  position: relative;
  padding-left: 15px;
  padding-top: 9px;
}
.smal_title {
  position: relative;
}
.smal_title::before {
  content: "";
  width: 30px;
  height: 30px;
  background-color: #89a155;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
}
.hero_btn button.btn.btn-primary {
  padding: 14px 19px 15px 18px;
}
.hero_Logo {
  position: absolute;
  top: -190px;
  left: -153px;
}
.hero_bg_1 {
  position: absolute;
  top: 115px;
  left: 0;
  opacity: 0.1;
}
.hero_bg_2 {
  position: absolute;
  top: 455px;
  left: 0;
}
.hero_bg_3 {
  position: absolute;
  top: 175px;
  right: 44px;
  z-index: 1;
}
.hero_bg_4 {
  position: absolute;
  top: 257px;
  right: 160px;
  z-index: 3;
}
.hero_bg_5 {
  position: absolute;
  top: 449px;
  right: 648px;
  z-index: 3;
}

/* Banner Global */
.breadcrumb_wrapper {
  position: relative;
  z-index: 0;
  padding-top: 142px;
  padding-bottom: 121px;
}
.breadcrumb_wrapper::before {
  content: "";
  background: #27293e;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}
.breadcrumb_wrapper::after {
  content: "";
  background: #89a155;
  width: 50%;
  height: 100%;
  position: absolute;
  top: 0;
  right: 0;
}
.breadcrumb_wrapper > .container {
  z-index: 1;
  position: relative;
}
.breadcrumb_wrapper a {
  text-decoration: none;
}
li.breadcrumb-item a {
  font-family: "Syne";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
}
.breadcrumb-item.active {
  font-family: "Syne";
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff !important;
}
ol.breadcrumb {
  justify-content: center;
}
.breadcrumb-item + .breadcrumb-item::before {
  color: #fff !important;
  content: "\f105";
  display: inline-block;
  padding-right: 10px !important;
  font-size: inherit;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.about_our_school.about_page {
  padding-top: 102px;
  padding-bottom: 103px;
}
.why_choose_wrapper.whychoose_about_pg {
  padding-top: 0;
}
.breadcrumb_outer h2 {
  color: #fff;
  font-weight: 700;
  text-align: center;
  padding-top: 14px;
}
@media (max-width: 1330px) {
  .hero_detail {
    padding-top: 249px;
    padding-left: 65px;
  }
  .hero_outer {
    background-position: center;
  }
}

@media (max-width: 991px) {
  .hero_bg_1 {
    display: none;
  }
  .hero_bg_2 {
    display: none;
  }
  .hero_bg_3 {
    display: none;
  }
  .hero_bg_4 {
    display: none;
  }
  .hero_bg_5 {
    display: none;
  }
  .hero_Logo {
    display: none;
  }
  .hero_outer {
    background-position: center;
  }
  .hero_detail {
    position: initial;
    padding-top: 125px;
    padding-left: 60px;
    padding-right: 60px;
    margin: 0 auto;
    text-align: center;
  }
  .smal_title {
    position: relative;
    max-width: 250px;
    width: 100%;
    text-align: center;
    margin: 0 auto;
  }
  .smal_title::before {
    position: absolute;
    top: 0;
    left: 22px;
    border-radius: 100%;
  }
  .hero_btn {
    padding-top: 15px;
  }
}

@media (max-width: 575px) {
  .smal_title {
    max-width: 226px;
  }
  .hero_detail {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 35px;
  }
}

@media (max-width: 480px) {
  .hero_detail {
    padding-left: 15px;
    padding-right: 15px;
  }
}
