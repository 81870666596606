.accordion-button:not(.collapsed) {
  color: #ffffff;
  background-color: #27293e;
  box-shadow: none;
  padding: 31px 25px 31px 21px;
}
.accordion-item h5 {
  color: #fff;
  font-family: "Syne";
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}
.accordion-button {
  padding: 31px 25px 31px 21px;
  background-color: #27293e !important;
  height: 93px;
}
.accordion-item {
  max-width: 413px;
  width: 100%;
  margin-bottom: 30px;
}
.accordion:first-child {
  padding-top: 49px;
}
.accordion-body {
  padding: 24px 17px 20px 21px;
  background: #ffffff;
  box-shadow: 0px 4px 35px rgb(0 0 0 / 5%);
}
.accordion-item:first-of-type {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.accordion-item {
  background-color: #fff;
  border: none;
}

.faq_pg_wrapper .accordion-button:not(.collapsed)::after {
  background-image: url("../../images/faq_arrow_2.png");
  transform: none;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("../../images/faq_arrow_2.png");
  transform: rotate(0);
}
.accordion-button::after {
  background-image: url("../../images/faq_arrow_1.png") !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain !important;
}
.accordion-button:focus {
  box-shadow: none !important;
}
.accordion-button:focus {
  z-index: 3;
  border-color: transparent;
  outline: 0;
  box-shadow: transparent;
}
.faq_wrapper {
  position: relative;
}
.faq_wrapper h5{
  margin-bottom: 0;
}
.faq_wrapper::after {
  content: "";
  background: url("../../images/faq_bg_2.png");
  width: 96px;
  height: 96px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 90px;
  right: 89px;
}
.faq_wrapper::before {
  content: "";
  background: url("../../images/faq_bg_1.png");
  width: 96px;
  height: 96px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: absolute;
  bottom: 232px;
  left: 78px;
}
.faq_bottom_title_box {
  background: #89a155;
  box-shadow: 0px 10px 10px rgb(0 0 0 / 5%);
  border-radius: 5px;
  max-width: 570px;
  width: 100%;
  margin: 0 auto;
  padding: 35px 33px 35px 33px;
  margin-top: 20px;
}
.faq_bottom_title_box a{
  text-decoration: none;
}
.faq_call_detail a {
  font-size: 18px;
  color: #fff;
  line-height: 21.6px;
  font-family: "Syne", sans-serif;
}
.faq_call_detail span {
  color: #fff;
  font-size: 16px;
  line-height: 19.36px;
}
span.call_us {
  display: block;
  margin-left: -40px;
}
span.email_us {
  display: block;
  margin-left: -82px;
}
.faq_call_detail p {
  margin: 0;
}
.faq_call {
  width: 15px;
}
.faq_call_detail {
  padding-left: 6px;
}
.faq_call_p {
  color: #fff;
}
.faq_call_outer.first {
  position: relative;
}
.faq_call_outer.first::after {
  content: "";
  background: #ffffff;
  opacity: 0.2;
  width: 1px;
  height: 40px;
  position: absolute;
  top: 0;
  right: -97px;
}
.faq_bottom_title_box {
  position: relative;
}
.faq_bottom_title_box::after {
  content: "";
  border: 1px dashed #fff;
  border-radius: 5px;
  position: absolute;
  top: 4px;
  right: 5px;
  width: 559px;
  height: 104px;
}
.faq_wrapper {
  padding: 100px 0;
}
.faq_wrapper .smal_title h5 {
  color: #27293e !important;
}
.faq_wrapper .our_team_title h2 {
  color: #27293e;
}
.faq_wrapper .our_team_btn .about_our_btn_2 button.btn.btn-primary {
  padding: 14px 30px 15px 30px;
}
/* FAQ page */

.faq_pg_wrapper .classes_workshop_title_outer {
  width: 100%;
  padding-bottom: 37px;
  max-width: 553px;
}
.faq_pg_wrapper .smal_title::before {
  left: 132px;
}
.faq_pg_wrapper .smal_title.smal_mid {
  max-width: 100%;
}
.faq_pg_wrapper .accordion:first-child {
  padding-top: 0;
}
.faq_pg_wrapper {
  padding-top: 108px;
}
.faq_pg_wrapper .accordion {
  width: 33.33%;
  padding-right: 32px;
}
.faq_pg_box_wrapper {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 1630px) {
  .faq_wrapper::after {
    top: 15px;
    right: 15px;
  }
}
@media (max-width: 1330px) {
  .accordion-item {
    max-width: 365px;
  }
  .accordion-item h5 {
    font-size: 20px;
  }
}

@media (max-width: 1230px) {
  .accordion-item h5 {
    font-size: 17px;
  }
  .accordion-item {
    max-width: 307px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 15px;
  }
}
@media (max-width: 991px) {
  .faq_wrapper::after {
    display: none;
  }
  .faq_wrapper::before {
    display: none;
  }
  .faq_wrapper {
    padding: 50px 0;
  }
  .accordion-item {
    max-width: 100%;
  }
  .faq_wrapper .smal_title::before {
    left: 14px;
  }
  .faq_bottom_title {
    padding-top: 15px;
  }
  .accordion:first-child {
    padding-top: 25px;
  }
  .faq_bottom_title_box {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .faq_bottom_title_box::after {
    min-height: 158px;
  }
  .faq_call_outer.first::after {
    display: none;
  }
  .faq_call_outer.first {
    padding-bottom: 15px;
  }
  .faq_pg_box_1.d-flex {
    flex-direction: column;
  }
  .faq_pg_wrapper .accordion {
    width: 100%;
    padding-right: 0;
  }
}
@media (max-width: 767px) {
  .accordion-button {
    padding: 15px 15px;
  }
}

@media (max-width: 575px) {
  .faq_bottom_title_box::after {
    content: "";
    border: 1px dashed #fff;
    border-radius: 5px;
    position: absolute;
    top: 4px;
    right: 0;
    max-width: 489px;
    height: 104px;
    width: 100%;
    left: 0;
    margin: 0 auto;
  }
  .faq_bottom_title_box {
    max-width: 505px;
  }
}

@media (max-width: 360px) {
  .faq_wrapper {
    padding: 30px 0;
  }
  .faq_bottom_title_box::after {
    max-width: 332px;
  }
}
